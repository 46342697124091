import React from "react"
import { Link } from "gatsby"
import s from "./head.module.css"
import ReactDOM from "react-dom";
import { links } from "../../../config"
import axios from "axios"
import logo from './logo.png'
export default () => {
	const [userName, setUserName] = React.useState(null);
	const [loading, setLoading] = React.useState(false)
	const renderLoginDom = () =>{
		return (userName ?
		 	<div className={s.loginContainer}>
				<Link className={s.innerlink} to={links.console}>控制台</Link>
				<span className={s.cutLine} />
				<div className={s.linkCtn}>
					<span>{userName}</span>
					<svg class={s.icon} focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" class="_24z0kyFs"><path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path></svg>
					<ul className={s.list}>
						<li><Link className={s.innerlink} to={links.cas}>创宇通行证</Link></li>
						<li><Link className={s.innerlink} to={links.logout}>退出登录</Link></li>
					</ul>
				</div>
			</div>
			:
			<div className={s.loginContainer}>
				<Link className={s.link} to={links.login}>
					登录
				</Link>
				<Link className={s.primary} to={links.register}>
					免费注册
				</Link>
			</div>)
	}
 	React.useEffect(async () =>{
	 try {
		setLoading(true)
		fetch(`/api/v2/users/user_info`, {
			method: 'GET'
		}).then(data => data.json()).then(res => {
			if (res.code === 200) {
				setLoading(false)
				setUserName(res?.results?.username);
			} else {
				setLoading(false)
			}
		}).catch(e => {
			setLoading(false)
			console.log(e)
		});
	 } catch (error) {
		setLoading(false)
	 }
	}, []);

	React.useEffect(() => {
    setTimeout(()=>{
			const loginElement = document.querySelector("#yaq-header-login");
			if (loginElement) {
				ReactDOM.render(renderLoginDom(), loginElement);
			}
		}, 1000)
  }, [userName, loading]);

	return null;

  // return (
	// 	<div className={s.header}>
	// 		<div className={s.content}>
  //       <div className={s.navContainer}>
  //         <span onClick={() => window.location.href = '/'} style={{ boxShadow: 'none' }}>
	// 				  <div className={s.logo}>
	// 					  <img className={s.img} src={logo} />
	// 					  {/* <span>ScanV</span> */}
	// 				  </div>
	// 			  </span>
  //       </div>
	// 			{loading ? <div className={s.loginContainer}></div> : (userName?
	// 				<div className={s.loginContainer}>
	// 					<span className={s.innerlink2} onClick={() => {
	// 						window.location.href = '/news/'
	// 					}}>
	// 						漏洞速递
	// 					</span>
	// 					<Link className={s.innerlink} to={links.console}>控制台</Link>
	// 					<span className={s.cutLine} />
	// 					<div className={s.linkCtn}>
	// 						<span>{userName}</span>
	// 						<svg class={s.icon} focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" class="_24z0kyFs"><path d="M8 11L3 6 3.7 5.3 8 9.6 12.3 5.3 13 6z"></path></svg>
	// 						<ul className={s.list}>
	// 							<li><Link className={s.innerlink} to={links.cas}>创宇通行证</Link></li>
	// 							<li><Link className={s.innerlink} to={links.logout}>退出登录</Link></li>
	// 						</ul>
	// 					</div>
	// 				</div>
	// 				:
	// 				<div className={s.loginContainer}>
	// 					<span className={s.link} onClick={() => {
	// 						window.location.href = '/news/'
	// 					}}>
	// 						漏洞速递
	// 					</span>
	// 					<Link className={s.link} to={links.login}>
	// 						登录
	// 					</Link>
	// 					<Link className={`${s.link} ${s.primary}`} to={links.register}>
	// 						免费注册
	// 					</Link>
	// 				</div>)
	// 			}
  //     </div>
	// 	</div>
  // )
}
